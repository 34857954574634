<!-- src/components/LoginForm.vue -->
<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100 w-full">
    <div class="w-full max-w-md lg:max-w-lg bg-white p-8 rounded-lg shadow-lg">
      <h2 class="text-2xl font-semibold text-gray-800 text-center mb-6">Login</h2>

      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="username" class="block text-gray-600 text-sm font-medium mb-2">Username</label>
          <input
              v-model="username"
              type="text"
              id="username"
              placeholder="Enter your username"
              class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div class="mb-4">
          <label for="password" class="block text-gray-600 text-sm font-medium mb-2">Password</label>
          <input
              v-model="password"
              type="password"
              id="password"
              placeholder="Enter your password"
              class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>

        <!-- Error message -->
        <p v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</p>

        <!-- Submit button -->
        <button
            type="submit"
            class="w-full bg-blue-500 text-white py-3 rounded-lg font-medium hover:bg-blue-600 transition duration-200"
        >
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';

  export default {
    setup() {
      const store = useStore();
      const router = useRouter();
      const username = ref('');
      const password = ref('');
      const errorMessage = ref('');

      const login = async () => {
        try {
          await store.dispatch('login', { username: username.value, password: password.value });
          router.push('/settings');  // Redirect after successful login
        } catch (error) {
          errorMessage.value = error.error || 'Login failed';
        }
      };

      return { username, password, errorMessage, login };
    },
  };
</script>

<style scoped>
  /* Optional additional styling */
</style>
